.texto-8 {
    font-size: .8rem;
}

.texto-12 {
    font-size: 1.2rem;
}

.tarjeta-servicio {
    padding-left: -2.25rem;
    padding-bottom: 5px;
    cursor: pointer;
    border-top: 1.5px solid lightgray;
    border-bottom: 1.5px solid lightgray;
}

.tarjeta-servicio:hover {
    background-color: rgb(241, 241, 241);
}

.tarjeta-servicio.active {
    background-color: rgb(217, 240, 255);
    color: black;
    
    border-top: 1.5px solid  rgb(217, 240, 255);
    border-bottom: 1.5px solid  rgb(217, 240, 255);
}

.tarjeta-servicio p {
    margin: 0;
    cursor: pointer;
    padding-left: 10px;
}