.input-date-picker {
    border-radius: 0!important;
}

#cmb-status-factura {
    padding: 0;
    padding-bottom: 5px;
}

.MuiChip-root{
    height: 23px!important;
}