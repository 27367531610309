.card-notificacion:hover {
    border-radius: 5px;
    background-color: rgba(0,0,0,.1);
}

.card-notificacion:hover span{
}

.card-notificacion.noleido:hover span{
}

.card-notificacion.noleido span{
    color: #0071db !important;
}